import * as React from "react";
import Layout from "../components/Layout";
import useColor from "../context/ColorContext";
import getClientsImageData from "../utils/functions/getClientsImageData";

const AboutPage = () => {
    const { setColor } = useColor();
    const images = getClientsImageData();

    React.useEffect(() => {
        setColor?.("blue");
    }, []);

    return (
        <Layout>
            <section>
                <h1 style={{ marginTop: 120 }}>O nas</h1>
                <p
                    style={{
                        maxWidth: 800,
                        marginTop: 16,
                        marginBottom: 32,
                        lineHeight: 1.5,
                    }}
                >
                    W tworzeniu agencji reklamowej Goldfish w 2007 roku
                    skorzystaliśmy między innymi z doświadczeń zdobytych podczas
                    pracy w dziale marketingu Big Star Ltd. Od tego czasu z
                    powodzeniem obsługujemy zlecenia klientów każdej wielkości -
                    od indywidualnych osób czy mikroprzedsiębiorstw po
                    korporacje. Stale rozwijamy park maszynowy, poszerzamy
                    know-how żeby realizować wizje klientów i wspierać ich
                    rozwój.
                </p>
            </section>
            <section>
                <h2>Zaufali nam między innymi:</h2>
                <div
                    style={{
                        display: "grid",
                        gap: 16,
                        gridTemplateColumns:
                            "repeat(auto-fit, minmax(200px, 1fr))",
                        placeItems: "center",
                        marginTop: 16,
                    }}
                >
                    {images.map((image, i) => (
                        <div
                            key={i + 1}
                            style={{
                                minWidth: "200px",
                                height: "150px",
                                border: "1px solid black",
                                background:
                                    'url("' +
                                    image.src +
                                    '") center center / contain no-repeat',
                            }}
                        ></div>
                    ))}
                </div>
            </section>
        </Layout>
    );
};

export default AboutPage;
